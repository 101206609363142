.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

body {
  padding: 0;
  margin: 0 auto;
  font-family: 'Comfortaa', sans-serif;
  background-color: #f8f8f8;
}

html {
  scroll-behavior: smooth;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 24px;
  right: 16px;
  top: 16px;
}

/* Color/shape of close button cross */
.bm-cross {
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100svh;
  left: 0px;
  opacity: 0.9;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  text-align: left;
  font-size: 0.6em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.map-container {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
