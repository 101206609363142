.progressVisualFull {
    display: flex;
    height:25px;
    margin: 20px 0;
    border-radius: 10px;
    border: 10px solid ;
  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 1.3s;    
  }
  
  .progressLabel {
    /* Nothing here, move along */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }

  @media only screen and (max-width: 600px) {
    .progressVisualFull {
        display: flex;
        height:20px;
        margin: 20px 0;
        border-radius: 5px;
        border: 7px solid ;
      }
}